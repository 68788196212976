/* Base container */
.modal-multi-select-container {
  position: relative;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Label styling */
.modal-multi-select-label {
  display: block;
  margin-bottom: 0.75rem;
  font-weight: 500;
  color: #333;
}

/* Trigger element styling */
.modal-multi-select-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 42px;
  padding: 0.625rem 1rem;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.modal-multi-select-trigger:hover {
  border-color: #adb5bd;
  background-color: #f8f9fa;
}

.modal-multi-select-trigger:focus {
  outline: none;
  border-color: #6366f1; /* Primary color */
  box-shadow: 0 0 0 0.2rem rgba(99, 102, 241, 0.25);
}

.selected-display {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.95rem;
}

.placeholder {
  color: #6c757d;
}

.trigger-icon {
  margin-left: 10px;
  color: #6c757d;
  font-size: 0.8rem;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  padding: 1.5rem;
}

/* Modal container */
.modal-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  width: 95%;
  max-width: 700px;
  max-height: 85vh;
  display: flex;
  flex-direction: column;
  animation: modalFadeIn 0.3s ease;
  overflow: hidden;
  padding: 10px;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Modal header */
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid #e9ecef;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.3rem;
  color: #212529;
  font-weight: 600;
}

/* Close button in header */
.close-button {
  background: none;
  border: none;
  font-size: 1.6rem;
  color: #6c757d;
  cursor: pointer;
  padding: 0.3rem 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.2s;
  margin-right: -0.4rem;
}

.close-button:hover {
  background-color: #dc3545;
  color: white;
}

/* Modal body */
.modal-body {
  flex: 1;
  padding: 1.5rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Search input */
.search-container {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.modal-search-input {
  width: 100%;
  padding: 0.7rem 1rem;
  border: 1px solid #ced4da;
  border-radius: 6px;
  font-size: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.modal-search-input:focus {
  outline: none;
  border-color: #6366f1; /* Primary color */
  box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.15);
}

/* Options list */
.options-list {
  flex: 1;
  overflow-y: auto;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
}

.option-item {
  display: flex;
  align-items: center;
  padding: 0.9rem 1.2rem;
  cursor: pointer;
  border-bottom: 1px solid #e9ecef;
  transition: background-color 0.2s;
  font-size: 0.95rem;
}

.option-item:last-child {
  border-bottom: none;
}

.option-item:hover {
  background-color: #f5f9ff;
}

.option-item.selected {
  background-color: #eef2ff;
}

/* Enhanced checkbox styling */
.option-item input[type="checkbox"] {
  margin-right: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #d1d5db;
  border-radius: 5px;
  outline: none;
  transition: all 0.2s;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
}

.option-item input[type="checkbox"]:checked {
  background-color: #6366f1; /* Primary color */
  border-color: #6366f1; /* Primary color */
}

.option-item input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.option-item input[type="checkbox"]:focus {
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.3);
}

.no-results {
  padding: 1.5rem;
  text-align: center;
  color: #6c757d;
  font-style: italic;
}

/* Modal footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 1.5rem;
  border-top: 1px solid #e9ecef;
  gap: 1rem;
}

.cancel-button,
.apply-button {
  padding: 0.7rem 1.4rem;
  border-radius: 6px;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.cancel-button {
  background-color: #fff;
  border: 1px solid #d1d5db;
  color: #4b5563;
}

.cancel-button:hover {
  background-color: #f9fafb;
  border-color: #9ca3af;
}

.apply-button {
  background-color: #6366f1; /* Primary color */
  border: 1px solid #6366f1; /* Primary color */
  color: #fff;
  font-weight: 600;
}

.apply-button:hover {
  background-color: #4f46e5; /* Darker primary color */
  border-color: #4f46e5; /* Darker primary color */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modal-container {
    width: 95%;
    max-width: 900px;
    max-height: 95vh;
  }
  
  .modal-body {
    padding: 1.25rem;
  }
}

@media (max-width: 576px) {
  .modal-overlay {
    padding: 1rem;
  }
  
  .modal-container {
    width: 100%;
    max-height: 85vh;
  }
  
  .modal-header {
    padding: 1rem 1.25rem;
  }
  
  .modal-header h3 {
    font-size: 1.2rem;
  }
  
  .modal-body {
    padding: 1rem;
  }
  
  .modal-footer {
    padding: 1rem 1.25rem;
  }
  
  .option-item {
    padding: 0.75rem 1rem;
  }
}